

<template>
    <div>
        
  
    </div>
  </template>
  
  <script>
  import router from "@/router";

  export default {
    name: 'Mawaqit',
    data() {
      return {
      
      };
    },
    mounted() {
    // Crea un temporizador que redirigirá a la nueva ruta después de 30 segundos
    setTimeout(() => {
      // Navega a la nueva ruta utilizando el objeto 'router'
      router.push("/");
    }, 15000); // 30000 milisegundos (30 segundos)
  }};
  </script>
  
  <style scoped>
  iframe {
    width: 100%;
    height: 1000px;
  }
  
  </style>