<template>
    <div class="socios">
        
        <h1 style="color: #ffffff; font-size: 50px; font-family: Changa; padding-top: 10px;">
            لائحة الواجب الشهري
        </h1>
        <Tablero/>
    
    </div>
</template>

<script>

import  Tablero from '../components/Tablero.vue';
export default {
    name: "socios",
    components:{
        Tablero,
        
    }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Changa:wght@800&family=Noto+Sans+Arabic:wght@900&display=swap");
</style>
