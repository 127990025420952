<template>
  <div class="container" style="padding-top: 20px">
    <b-pagination
      :per-page="perPage"
      hide-goto-end-buttons
      v-model="currentPage"
      pills
      :total-rows="rows"
      size="lx"
      aria-controls="my-table"
      align="fill"
    ></b-pagination>

    <b-table
      foot-clone
      :tbody-tr-class="rowClass"
      table-variant="light"
      id="my-table"
      head-variant="light"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
      small
      responsive
      striped
      primary-key="Id"
      :fields="fields"
      v-if="loaded"
    >
      <template #cell()="{ field, value }">
        <div :style="field.style" v-text="value" />
      </template>
    </b-table>
    <img v-else src="@/assets/logo/editor-0.6s-47px.gif" alt="cargado.." />

   
    <div class="alert alert-primary"  style="font-family: Changa">يرجى التواصل معنا في حال وجود أي أخطاء</div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";

export default {
  name: "Tablero",
  data() {
    return {
      loaded: false,
      rows: 0,
      perPage: 18,
      currentPage: 1,
      items: [],
      fields: [
        {
          key: "Deciembre",
          label: "Dic",
          tdClass: this.isCurrentMonth("dic") ? "bg-warning" : "",
          thClass: this.isCurrentMonth("dic") ? "bg-warning" : "",
        },
        {
          key: "Noviembre",
          label: "Nov",
          tdClass: this.isCurrentMonth("nov") ? "bg-warning" : "",
          thClass: this.isCurrentMonth("nov") ? "bg-warning" : "",
        },
        {
          key: "Octubre",
          label: "Oct",
          tdClass: this.isCurrentMonth("oct") ? "bg-warning" : "",
          thClass: this.isCurrentMonth("oct") ? "bg-warning" : "",
        },
        {
          key: "Setiembre",
          label: "Set",
          tdClass: this.isCurrentMonth("set") ? "bg-warning" : "",
          thClass: this.isCurrentMonth("set") ? "bg-warning" : "",
        },
        {
          key: "Agosto",
          label: "Ago",
          tdClass: this.isCurrentMonth("ago") ? "bg-warning" : "",
          thClass: this.isCurrentMonth("ago") ? "bg-warning" : "",
        },
        {
          key: "Julio",
          label: "Jul",
          tdClass: this.isCurrentMonth("jul") ? "bg-warning" : "",
          thClass: this.isCurrentMonth("jul") ? "bg-warning" : "",
        },
        {
          key: "Junio",
          label: "Jun",
          tdClass: this.isCurrentMonth("jun") ? "bg-warning" : "",
          thClass: this.isCurrentMonth("jun") ? "bg-warning" : "",
        },
        {
          key: "Mayo",
          label: "May",
          tdClass: this.isCurrentMonth("may") ? "bg-warning" : "",
          thClass: this.isCurrentMonth("may") ? "bg-warning" : "",
        },
        {
          key: "Abril",
          label: "Abr",
          tdClass: this.isCurrentMonth("abr") ? "bg-warning" : "",
          thClass: this.isCurrentMonth("abr") ? "bg-warning" : "",
        },
        {
          key: "Marzo",
          label: "Mar",
          tdClass: this.isCurrentMonth("mar") ? "bg-warning" : "",
          thClass: this.isCurrentMonth("mar") ? "bg-warning" : "",
        },
        {
          key: "Febrero",
          label: "Feb",
          tdClass: this.isCurrentMonth("feb") ? "bg-warning" : "",
          thClass: this.isCurrentMonth("feb") ? "bg-warning" : "",
        },
        {
          key: "Enero",
          label: "Ene",
          tdClass: this.isCurrentMonth("ene") ? "bg-warning" : "",
          thClass: this.isCurrentMonth("ene") ? "bg-warning" : "",
        },
        {
          key: "Socio",
          label: "Nombre",
          style: {
            fontFamily: "Changa",
            fontSize: "21px",
            color: "black",
            fontStyle: "bold",
          },
          formatter: (value) => value.toUpperCase(),
        },
        {
          key: "Cuota",
          label: "€",
          style: { color: "black", fontStyle: "bold" },
        },
        {
          key: "Id",
          label: "Targeta",
          style: {
            fontFamily: "Arial",
            fontSize: "20px",
            color: "black",
            fontStyle: "bold",
        
          },
        },
      ],
    };
  },
  async mounted() {
    try {
      const response = await axios.get("http://mesquitaalfath.cat:3001/socios");
      this.items = response.data;
      this.rows = this.items.length;
      this.loaded = true;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    setInterval(() => {
      if (this.currentPage < 15) {
        this.currentPage++;
      } else {
        this.currentPage = 1;
        router.push("/about");
      }
    }, 15000);
  },
  computed: {
    datosMayuscula() {
      return this.items.map((dato) => ({
        Id: dato.Id,
        Socio: dato.Socio.toUpperCase(),
      }));
    },
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (
        (item.Enero != "0") &
        (item.Febrero != "0") &
        (item.Noviembre != "0") &
        (item.Deciembre != "0")
      )
        return "table-success";
    },
    isCurrentMonth(monthKey) {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      return monthKey === this.getMonthName(currentMonth);
    },
    getMonthName(monthNumber) {
      const months = [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "set",
        "oct",
        "nov",
        "dic",
      ];
      return months[monthNumber - 1];
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Changa:wght@600&family=Harmattan:wght@700&display=swap");

</style>
