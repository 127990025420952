<template>
  <div id="app">
    <AppHeader v-show="$route.path !== '/'  && $route.path !== '/mawaqit' "/>
    <router-view/>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
export default {
    name: "App",
    components: {
        AppHeader,
        
    },
};
</script>

<style>

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
