import Vue from 'vue'
import VueRouter from 'vue-router'
import Socios from "../views/Socios.vue";
import HomeView from '../views/HomeView.vue'
import Mawaqit from '../views/Mawaqit.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },{
    path: "/",
    name: "socios",
    component: Socios,
},{
  path: "/mawaqit",
  name: "mawaqit",
  component: Mawaqit,
},

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
