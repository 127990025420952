

<template>
  <div>
    <h1>home</h1>

  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
    
    };
  },
};
</script>

<style scoped>


</style>