<template>
    <div class="header">
        <nav>
            <div class="show-logo">
                <div id="logo">
                    <a><img src="../assets/logo.png" /></a>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: "AppHeader",
};
</script>

<style>
#logo img {
    width: 10%;
    height: auto;
}

body {
    background-image: url("../assets/bg1.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #999;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #ffffff;
}


</style>
